.videoContainer {
    display: flex;
    width: 100%; 
    height: 100%; 
    position: relative;
  }

  .videoContainerMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
  .videoIframe {
    width: 100%; 
    height: 100%; 
    border: none;
    border-radius: 20px;
  }
  
  @media (max-width: 768px) {
    .videoContainer {
      margin-top: 50px;
    }
  
    .videoIframe {
      width: 100%; 
      height: auto; 
    }
  }
  