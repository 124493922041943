@import '../../styles/customMediaQueries.css';

.root {

  display: flex;
  flex-direction: column;

  
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); 
  border-radius: 40px;
  width: 500px; 
  height: 350px; 
  overflow: hidden; 
  object-fit: contain;
  margin: 20px 0; 

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.05); 
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.info {

  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceContainer {
  display: flex;
}

.priceValue {

  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);


  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {

  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;


  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {

  composes: textSmall from global;
  font-weight: var(--fontWeightBold);

  color: black;
  margin: 0;
}

.authorInfo {

  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {

  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.teamBuilding {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
  margin-top: -12px;
}

.da {
  display: flex;
}

.link {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  text-decoration: none;
}

.image {
  width: 100%; 
  height: auto; 
  border-radius: 40px;
  margin-bottom: 8px; 
  object-fit: contain;
}

.title {
  font-size: 1.2rem;
  font-weight: bold; 
  color: black; 
  text-align: center; 
}

@media (max-width: 768px) {
  .aspectRatioWrapper {
    background: none;
    border-radius: 10px;
    width: 300px; 
    height: 250px;
    overflow: hidden; 
    object-fit: contain;
 
   
  
    @media (--viewportMedium) {
      &:hover {
        transform: scale(1.05); 
        box-shadow: var(--boxShadowListingCard);
      }
    }
  }
}