.formContainer { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.inputField {
  width: 100%;
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  display: block;
}

.nameRow {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
}

.nameInput {
  flex-grow: 1;
  font-size: 1.2rem;  
  border-radius: 20px;
  margin-bottom: 2px;
}

.button {
  font-size: 1.2rem;  
  width: 300px;
  color: white;
  margin: 5px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #0048ff;
}

.button:hover {
  background-color: #5d85ff;
}

.header {
  text-align: center;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .formContainer {
    margin-top: 5vh;
    margin-bottom: 5vh;
    height: 20vh;
  }
  .nameInput {
    margin-bottom: 5px;
    border-radius: 20px;
  }
}
