.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.subContainerWrapper {
  overflow: hidden;
  position: relative;
}

.subContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  color: white;
  background-color: #ed514a;
  position: absolute;
  width: 300%;
  animation: scrollLeft 20s linear infinite;
}

.subContainer > div {
  flex: 1;
  text-align: center;
}

.subContainer > div > div {
  margin: 10px 0;
}

.header {
  font-weight: bold;
}

.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--zIndexTopbar);
}

.desktopTopbarLandingPage {
  background-color: white;
}

.desktopTopbar,
.desktopTopbarLandingPageWithScroll {
  background-color: white;
}

.main {
  display: grid;
  background-color: white;
  
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customClass {
  position: fixed;
  bottom: 80px;
  right: 5px;
  height: 50px;
  width: 80px;
  color: #012fa7;
  cursor: pointer;
  z-index: 1000;
  flex-direction: row;
  margin: 2px;
}

@media screen and (max-width: 768px) {
  .subContainerWrapper {
    height: 100px;
  }

  .subContainer {
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 5px;
    white-space: nowrap;
    animation: scrollLeft 20s linear infinite;
  }

  .subContainer > div {
    text-align: center;
    min-width: auto;
  }

  .subContainer > div > div {
    margin: 15px 0;
  }

  .header {
    font-size: 12px;
  }

  .subheader {
    font-size: 12px;
    width: 100%;
  }

  .customClass {
    position: fixed;
    bottom: 10px;
    right: 5px;
    height: 50px;
    width: 80px;
    color: #012fa7;
    cursor: pointer;
    z-index: 1000;
    flex-direction: row;
    margin: 2px;
  }

  @keyframes scrollLeft {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-66.666%);
    }
  }
}

