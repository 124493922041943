.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px; /* space between buttons */
}

.parlaConNoiButton, .primaryButton {
  background-color: #0048ff;
  color: #fff;
  font-size: 1.2rem;
  width: 300px;
  height: 55px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  padding: 0 24px;
}

.parlaConNoiButton:hover,
.primaryButton:hover {
  background-color: #0056b3;
}

.popupButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%; /* Full width like other modal options */
  margin-bottom: 5px;
  height: 45px;
  background-color: white;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  color: black;
  transition: background-color 0.3s ease;
}

.popupButtonWrapper:hover {
  background-color: #f0f0f0; /* Subtle hover effect */
}

.popupButton {
  border: none;
  background: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: black;
  margin: 0; /* Remove default margin to align with icon */
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure it floats above everything */
}

/* The white box that pops up */
.modalContent {
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  width: 320px; /* adjust as desired */
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.3);
  position: relative;
}

/* Buttons within the modal (WhatsApp, Calendly, Annulla) */
.modalOption, .modalClose {
  display: block; /* full width */
  width: 100%;
  margin-bottom: 5px;
  color:black;
  font-size: 1.2rem;
  border: none;
  height: 45px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.modalClose {
  background-color: #0048ff;
  color: #fff;
}

.modalOption:hover, .modalClose:hover {
  background-color: #0056b3;
}

/* 
  ==============
  RESPONSIVE
  ==============
*/
@media (max-width: 768px) {
  .buttonContainer {
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modalContent {
    width: 90%; /* take more space on small screens */
  }
}


.calendlyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendlyContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
}

.calendlyIframe {
  width: 100%;
  height: 500px;
  border: none;
}

.modalClose {
  margin-top: 10px;
  padding: 10px 20px;
  background: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}
