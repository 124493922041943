.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.subContainer {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 20px; /* Adjust the gap as needed */
  color: white;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
}

.subContainer > div {
  flex: 1;
  text-align: center;
  margin-bottom: 20px; /* Add margin to create space between headers */
}

.header {
  font-weight: bold;
  margin-bottom: 20px; /* Add margin to create space between headers */
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container {
  margin-top: 10px;
  margin: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.primaryButton {
  width: 200px; /* Fixed width for the button */
  height: 50px; /* Adjust this value as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
  }

  .subContainer {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center;
  }

  .primaryButton {
    width: 200px; /* Fixed width for the button */
  }
}
