/* SurveyForm.module.css */

.surveyForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

.step {
  width: 100%;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #000000;
}

p {
  font-size: 16px;
  color: #000000;
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  cursor: pointer;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card:hover {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.selected {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  color: #007bff;
}

.emoji {
  font-size: 24px;
  margin-bottom: 10px;
}

.placeholder {
  font-size: 16px;
  color: #888;
  margin-top: 5px;
}

.nextButton,
.backButton,
.submitButton,
.primaryButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
}

.nextButton:hover,
.backButton:hover,
.submitButton:hover,
.primaryButton:hover {
  background-color: #0056b3;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.card img {
  width: 36px;
  height: 36px;
  margin-bottom: 10px;
  object-fit: contain;
}

.emojiImage {
  width: 36px;
  height: 36px;
  margin-bottom: 10px;
}

.emailLink {
  text-decoration: none;
  cursor: pointer;
  text-decoration: none;
}

.emailLink:hover {
  color: #003399;
}

@media (max-width: 768px) {
  .surveyForm {
    padding: 10px;
    margin-top: 40px;
    width: 90vw;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .cardContainer {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .card {
    width: 90%;
    padding: 10px;
    margin: 2px 0;
  }

  .emoji {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .placeholder {
    font-size: 14px;
  }

  .nextButton,
  .backButton,
  .submitButton,
  .primaryButton {
    padding: 8px 16px;
    font-size: 14px;
  }
}
