@import '../../styles/customMediaQueries.css';

.surveyContainer {
  display: flex;
  width: 100%;
  height: auto; /* Let height expand based on content */
  box-sizing: border-box; 
}

.backgroundVideo{
  position: absolute;
  width: 100%;
  height:100vh;
  object-fit: cover;
  z-index: 1;
}

.emptyDiv2 {
  position: relative;
  flex: 1;
  text-align: center;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.emptyDiv2 {
  flex: 1 1 33%;
  padding: 10px;
  box-sizing: border-box;
}

.mobileContainer{
  height: 100vh;
}

.emptyDiv {
  flex: 1;
}

.emptyDiv2 {
  flex: 10;
}

.emptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: wrap;
  padding: 50px;
  font-size: 5rem;
  line-height: 1.5;
  margin-top: 50px;
  color: #ed514a;
}

.surveyForm{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, 0);
  }

  50% {
    transform: translate(-50%, -10px);
  }
}

.contentContainer{
  justify-content: center;
}

.tooltip {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff4cc;
  padding: 10px;
  border: 1px solid #f1c40f;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 999;
  white-space: nowrap;
  opacity: 1;
  animation: bounce 1.5s infinite ease-in-out;
}

.surveyContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.emptyDiv2 {
  flex: 1 1 33%; 
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teamText{
  font-size: 1.2rem;
  color:white;
  width: 40%;
  margin: 0 auto;
  text-align: justify;
  margin-bottom: 20px;
}

.contentTitle{
  font-size: 2rem;
}


@media (max-width: 768px) {
  .surveyContainer {
    flex-direction: column;
  }

  .contentTitle{
    font-size: 1.5rem;
  }

  .mobileContainer{
    height: 80vh;
  }

  .emptyDiv2 {
    flex: 1 1 auto;
    text-align: center;
  }

  .backgroundVideo{
    height:100vh;
  }

  .teamText{
    width: 80%;
    text-align: justify;
  }

  .emptyDiv2 {
    padding: 0px;
  }
}