.container {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    height: 80vh;
    margin-bottom: 10vh;
  }

  .logoGrid {
    grid-template-columns: 1fr 1fr; 
  }

  .logoGrid .logo:last-child {
    grid-column: 1 / -1; 
    justify-self: center;
  }

}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 120px;
  border-radius: 5px;
  box-sizing: border-box;
}

.logoImage {
  max-width: 100%;
  max-height: 80%;
  object-fit: contain;
}
