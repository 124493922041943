@charset "UTF-8";
:global(.rbc-btn) {
  color: inherit;
  font: inherit;
  margin: 0;
}

button:global(.rbc-btn) {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled]:global(.rbc-btn) {
  cursor: not-allowed;
}

button:global(.rbc-input)::-moz-focus-inner {
  border: 0;
  padding: 0;
}

:global(.rbc-calendar) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

:global(.rbc-calendar) *,
:global(.rbc-calendar) *:before,
:global(.rbc-calendar) *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

:global(.rbc-abs-full),
:global(.rbc-row-bg) {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

:global(.rbc-ellipsis),
:global(.rbc-show-more),
:global(.rbc-row-segment) :global(.rbc-event-content),
:global(.rbc-event-label) {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.rbc-rtl) {
  direction: rtl;
}

:global(.rbc-off-range) {
  color: #999999;
}

:global(.rbc-off-range-bg) {
  background: #e6e6e6;
}

:global(.rbc-header) {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}
:global(.rbc-header) + :global(.rbc-header) {
  border-left: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-header) + :global(.rbc-header) {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
:global(.rbc-header) > a,
:global(.rbc-header) > a:active,
:global(.rbc-header) > a:visited {
  color: inherit;
  text-decoration: none;
}

:global(.rbc-button-link) {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

:global(.rbc-row-content) {
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

:global(.rbc-row-content-scrollable) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
:global(.rbc-row-content-scrollable) :global(.rbc-row-content-scroll-container) {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
:global(.rbc-row-content-scrollable) :global(.rbc-row-content-scroll-container)::-webkit-scrollbar {
  display: none;
}

:global(.rbc-today) {
  background-color: #eaf6ff;
}

:global(.rbc-toolbar) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
:global(.rbc-toolbar) :global(.rbc-toolbar-label) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
:global(.rbc-toolbar) button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
:global(.rbc-toolbar) button:active,
:global(.rbc-toolbar) button:global(.rbc-active) {
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0: global(0.125));
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0: global(0.125));
  background-color: #e6e6e6;
  border-color: #adadad;
}
:global(.rbc-toolbar) button:active:hover,
:global(.rbc-toolbar) button:active:focus,
:global(.rbc-toolbar) button:global(.rbc-active):hover,
:global(.rbc-toolbar) button:global(.rbc-active):focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
:global(.rbc-toolbar) button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
:global(.rbc-toolbar) button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

:global(.rbc-btn-group) {
  display: inline-block;
  white-space: nowrap;
}
:global(.rbc-btn-group) > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:global(.rbc-btn-group) > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
:global(.rbc-rtl) :global(.rbc-btn-group) > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
:global(.rbc-rtl) :global(.rbc-btn-group) > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:global(.rbc-btn-group) > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
:global(.rbc-btn-group) button + button {
  margin-left: -1px;
}
:global(.rbc-rtl) :global(.rbc-btn-group) button + button {
  margin-left: 0;
  margin-right: -1px;
}
:global(.rbc-btn-group) + :global(.rbc-btn-group),
:global(.rbc-btn-group) + button {
  margin-left: 10px;
}

@media (max-width: 767px) {
  :global(.rbc-toolbar) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
:global(.rbc-event),
:global(.rbc-day-slot) :global(.rbc-background-event) {
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
:global(.rbc-slot-selecting) :global(.rbc-event),
:global(.rbc-slot-selecting) :global(.rbc-day-slot) :global(.rbc-background-event),
:global(.rbc-day-slot) :global(.rbc-slot-selecting) :global(.rbc-background-event) {
  cursor: inherit;
  pointer-events: none;
}
:global(.rbc-event):global(.rbc-selected),
:global(.rbc-day-slot) :global(.rbc-selected):global(.rbc-background-event) {
  background-color: #265985;
}
:global(.rbc-event):focus,
:global(.rbc-day-slot) :global(.rbc-background-event):focus {
  outline: 5px auto #3b99fc;
}

:global(.rbc-event-label) {
  font-size: 80%;
}

:global(.rbc-event-overlaps) {
  -webkit-box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0: global(0.5));
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0: global(0.5));
}

:global(.rbc-event-continues-prior) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global(.rbc-event-continues-after) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.rbc-event-continues-earlier) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:global(.rbc-event-continues-later) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:global(.rbc-row) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

:global(.rbc-row-segment) {
  padding: 0 1px 1px 1px;
}
:global(.rbc-selected-cell) {
  background-color: rgba(0, 0, 0, 0: global(0.1));
}

:global(.rbc-show-more) {
  background-color: rgba(255, 255, 255, 0: global(0.3));
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #3174ad;
}
:global(.rbc-show-more):hover,
:global(.rbc-show-more):focus {
  color: #265985;
}

:global(.rbc-month-view) {
  position: relative;
  border: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

:global(.rbc-month-header) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

:global(.rbc-month-row) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
:global(.rbc-month-row) + :global(.rbc-month-row) {
  border-top: 1px solid #ddd;
}

:global(.rbc-date-cell) {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
:global(.rbc-date-cell):global(.rbc-now) {
  font-weight: bold;
}
:global(.rbc-date-cell) > a,
:global(.rbc-date-cell) > a:active,
:global(.rbc-date-cell) > a:visited {
  color: inherit;
  text-decoration: none;
}

:global(.rbc-row-bg) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden;
}

:global(.rbc-day-bg) {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}
:global(.rbc-day-bg) + :global(.rbc-day-bg) {
  border-left: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-day-bg) + :global(.rbc-day-bg) {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

:global(.rbc-overlay) {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0: global(0.25));
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0: global(0.25));
  padding: 10px;
}
:global(.rbc-overlay) > * + * {
  margin-top: 1px;
}

:global(.rbc-overlay-header) {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

:global(.rbc-agenda-view) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: auto;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) :global(.rbc-agenda-time-cell) {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-agenda-view) table:global(.rbc-agenda-table) tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) tbody > tr + tr {
  border-top: 1px solid #ddd;
}
:global(.rbc-agenda-view) table:global(.rbc-agenda-table) thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-agenda-view) table:global(.rbc-agenda-table) thead > tr > th {
  text-align: right;
}

:global(.rbc-agenda-time-cell) {
  text-transform: lowercase;
}
:global(.rbc-agenda-time-cell) :global(.rbc-continues-after):after {
  content: ' »';
}
:global(.rbc-agenda-time-cell) :global(.rbc-continues-prior):before {
  content: '« ';
}

:global(.rbc-agenda-date-cell),
:global(.rbc-agenda-time-cell) {
  white-space: nowrap;
}

:global(.rbc-agenda-event-cell) {
  width: 100%;
}

:global(.rbc-time-column) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
:global(.rbc-time-column) :global(.rbc-timeslot-group) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

:global(.rbc-timeslot-group) {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

:global(.rbc-time-gutter),
:global(.rbc-header-gutter) {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

:global(.rbc-label) {
  padding: 0 5px;
}

:global(.rbc-day-slot) {
  position: relative;
}
:global(.rbc-day-slot) :global(.rbc-events-container) {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
:global(.rbc-day-slot) :global(.rbc-events-container):global(.rbc-rtl) {
  left: 10px;
  right: 0;
}
:global(.rbc-day-slot) :global(.rbc-event),
:global(.rbc-day-slot) :global(.rbc-background-event) {
  border: 1px solid #265985;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
:global(.rbc-day-slot) :global(.rbc-background-event) {
  opacity: 0 (0.75);
}
:global(.rbc-day-slot) :global(.rbc-event-label) {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
  width: auto;
}
:global(.rbc-day-slot) :global(.rbc-event-content) {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
:global(.rbc-day-slot) :global(.rbc-time-slot) {
  border-top: 1px solid #f7f7f7;
}

:global(.rbc-time-view-resources) :global(.rbc-time-gutter),
:global(.rbc-time-view-resources) :global(.rbc-time-header-gutter) {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
:global(.rbc-time-view-resources) :global(.rbc-time-header) {
  overflow: hidden;
}
:global(.rbc-time-view-resources) :global(.rbc-time-header-content) {
  min-width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
}
:global(.rbc-time-view-resources) :global(.rbc-time-header-cell-single-day) {
  display: none;
}
:global(.rbc-time-view-resources) :global(.rbc-day-slot) {
  min-width: 140px;
}
:global(.rbc-time-view-resources) :global(.rbc-header),
:global(.rbc-time-view-resources) :global(.rbc-day-bg) {
  width: 140px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
}

:global(.rbc-time-header-content) + :global(.rbc-time-header-content) {
  margin-left: -1px;
}

:global(.rbc-time-slot) {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}
:global(.rbc-time-slot):global(.rbc-now) {
  font-weight: bold;
}

:global(.rbc-day-header) {
  text-align: center;
}

:global(.rbc-slot-selection) {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0: global(0.5));
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

:global(.rbc-slot-selecting) {
  cursor: move;
}

:global(.rbc-time-view) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
:global(.rbc-time-view) :global(.rbc-time-gutter) {
  white-space: nowrap;
  text-align: right;
}
:global(.rbc-time-view) :global(.rbc-allday-cell) {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
:global(.rbc-time-view) :global(.rbc-allday-cell) + :global(.rbc-allday-cell) {
  border-left: 1px solid #ddd;
}
:global(.rbc-time-view) :global(.rbc-allday-events) {
  position: relative;
  z-index: 4;
}
:global(.rbc-time-view) :global(.rbc-row) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 20px;
}

:global(.rbc-time-header) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
:global(.rbc-time-header):global(.rbc-overflowing) {
  border-right: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-time-header):global(.rbc-overflowing) {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
:global(.rbc-time-header) > :global(.rbc-row):first-child {
  border-bottom: 1px solid #ddd;
}
:global(.rbc-time-header) > :global(.rbc-row):global(.rbc-row-resource) {
  border-bottom: 1px solid #ddd;
}

:global(.rbc-time-header-cell-single-day) {
  display: none;
}

:global(.rbc-time-header-content) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-time-header-content) {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
:global(.rbc-time-header-content) > :global(.rbc-row):global(.rbc-row-resource) {
  border-bottom: 1px solid #ddd;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

:global(.rbc-time-content) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
:global(.rbc-time-content) > :global(.rbc-time-gutter) {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
:global(.rbc-time-content) > * + * > * {
  border-left: 1px solid #ddd;
}
:global(.rbc-rtl) :global(.rbc-time-content) > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
:global(.rbc-time-content) > :global(.rbc-day-slot) {
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

:global(.rbc-current-time-indicator) {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

/* ADDED CSS */
.listItem {
  cursor: pointer;
  transition: background-color 0.3s;
}

.listItem:hover {
  background-color: #012fa7;
  color: white;
}

.button{
  background-color: #012fa7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-left: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}